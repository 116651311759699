// Source: https://www.sii.org.il/media/3211/קודים-לייצוג-שמות-של-מדינות-לרבות-ישויות.pdf
export const hebrewCountryNames: Record<string, string> = {
  AF: "אפגניסטן",
  AX: "איי אולאנד",
  AL: "אלבניה",
  DZ: "אלג'יריה",
  AS: "סמואה האמריקנית",
  AD: "אנדורה",
  AO: "אנגולה",
  AI: "אנגווילה",
  AQ: "אנטארקטיקה",
  AG: "ה אנטיגואה",
  AR: "ארגנטינה",
  AM: "ארמניה",
  AW: "ארובה",
  AU: "אוסטרליה",
  AT: "אוסטרייה",
  AZ: "אזרבייג'אן",
  BS: "איי בהאמה",
  BH: "בחרין",
  BD: "בנגלדש",
  BB: "ברבדוס",
  BY: "בלארוס",
  BE: "בלגייה",
  BZ: "בליז",
  BJ: "בנין",
  BM: "ברמודה",
  BT: "בוטאן",
  BO: "בוליוויה",
  BQ: "בונר, סינט אסטטיוס וסאבה",
  BA: "בוסנייה והרצגובינה",
  BW: "בוצואנה",
  BV: "האי בובה",
  BR: "ברזיל",
  IO: "טריטוריה בריטית באוקיינוס ההודי",
  BN: "ברוניי דארוסלאם",
  BG: "בולגריה",
  BF: "בורקינה פאסו",
  BI: "בורונדי",
  CV: "קאבו ורדי",
  KH: "קמבודיה",
  CM: "קמרון",
  CA: "קנדה",
  KY: "איי קיימן",
  CF: "הרפובליקה המרכז אפריקנית",
  TD: "צ'אד",
  CL: "צ'ילה",
  CN: "סין",
  CX: "האי כריסמס",
  CC: "איי קוקוס",
  CO: "קולומבייה",
  KM: "איי קומורו",
  CD: "הרפובליקה הדמוקרטית של קונגו",
  CG: "קונגו",
  CK: "איי קוק",
  CR: "קוסטה ריקה",
  HR: "קרואטיה",
  CU: "קובה",
  CW: "קורסאו",
  CY: "קפריסין",
  CZ: "צ'כיה",
  DK: "דנמרק",
  DJ: "ג'יבוטי",
  DM: "דומיניקה",
  DO: "הרפובליקה הדומיניקנית",
  EC: "אקוודור",
  EG: "מצרים",
  SV: "אל סלוודור",
  GQ: "גינאה המשוונית",
  ER: "אריתראה",
  EE: "אסטוניה",
  SZ: "אסוואטיני",
  ET: "אתיופיה",
  FK: "איי פוקלנד",
  FO: "איי פארו",
  FJ: "פיג'י",
  FI: "פינלנד",
  FR: "צרפת",
  GF: "גיאנה הצרפתית",
  PF: "פולינזיה הצרפתית",
  TF: "טריטוריות צרפתיות דרומיות",
  GA: "גבון",
  GM: "גמבייה",
  GE: "גאורגייה",
  DE: "גרמניה",
  GH: "גאנה",
  GI: "גיברלטר",
  GR: "יוון",
  GL: "גרינלנד",
  GD: "גרנדה",
  GP: "גואדלופ",
  GU: "גואם",
  GT: "גואטמלה",
  GG: "גרנזי",
  GN: "גינאה",
  GW: "גינאה ביסאו",
  GY: "גיאנה",
  HT: "האיטי",
  HM: "האי הרד ואיי מקדונלד",
  VA: "וטיקן (הכס הקדוש)",
  HN: "הונדורס",
  HK: "הונג קונג",
  HU: "הונגריה",
  IS: "איסלנד",
  IN: "הודו",
  ID: "אינדונזיה",
  IR: "איראן",
  IQ: "עיראק",
  IE: "אירלנד",
  IM: "האי מאן",
  IL: "ישראל",
  IT: "איטליה",
  CI: "חוף השנהב",
  JM: "ג'מייקה",
  JP: "יפן",
  JE: "ג'רזי",
  JO: "ירדן",
  KZ: "קזח'סטן",
  KE: "קניה",
  KI: "קיריבאס",
  KP: "קוראה הצפונית",
  KR: "קוראה הדרומית",
  KW: "כווית",
  KG: "קירגיזסטן",
  LA: "לאוס",
  LV: "לטבייה",
  LB: "לבנון",
  LS: "לסוטו",
  LR: "ליבריה",
  LY: "לוב",
  LI: "ליכטנשטיין",
  LT: "ליטא",
  LU: "לוקסמבורג",
  MO: "מקאו",
  MG: "מדגסקר",
  MW: "מלאווי",
  MY: "מלזיה",
  MV: "המלדיוויים",
  ML: "מאלי",
  MT: "מלטה",
  MH: "איי מרשל",
  MQ: "מרטיניק",
  MR: "מאוריטניה",
  MU: "מאוריציוס",
  YT: "מאיוט",
  MX: "מקסיקו",
  FM: "מיקרונזיה",
  MD: "מולדובה",
  MC: "מונקו",
  MN: "מונגוליה",
  ME: "מונטנגרו",
  MS: "מונסראט",
  MA: "מרוקו",
  MZ: "מוזמביק",
  MM: "מיאנמר",
  NA: "נמיביה",
  NR: "נאורו",
  NP: "נפאל",
  NL: "הולנד",
  NC: "קלדוניה החדשה",
  NZ: "ניו זילנד",
  NI: "ניקרגואה",
  NE: "ניז'ר",
  NG: "ניגריה",
  NU: "ניואה",
  NF: "האי נורפוק",
  MK: "מקדוניה הצפונית",
  MP: "איי מריאנה הצפוניים",
  NO: "נורווגיה",
  OM: "עומאן",
  PK: "פקיסטן",
  PW: "פאלאו",
  PS: "הרשות הפלסטינית",
  PA: "פנמה",
  PG: "פפואה גינאה החדשה",
  PY: "פרגוואי",
  PE: "פרו",
  PH: "הפיליפינים",
  PN: "פיטקרן",
  PL: "פולין",
  PT: "פורטוגל",
  PR: "פוארטו ריקו",
  QA: "קטר",
  RE: "ראוניון",
  RO: "רומניה",
  RU: "רוסיה",
  RW: "רואנדה",
  BL: "סן ברתלמי",
  SH: "סנט הלנה, אסנשן וטריסטן דה קונה",
  KN: "סנט קיטס ונוויס",
  LC: "סנט לוסייה",
  MF: "סן מרטן (החלק הצרפתי)",
  PM: "סן פייר ומיקלון",
  VC: "סנט וינסנט והגרנדינים",
  WS: "סמואה",
  SM: "סאן מרינו",
  ST: "סאו טומה ופרינסיפה",
  SA: "ערב הסעודית",
  SN: "סנגאל",
  RS: "סרבייה",
  SC: "סיישל",
  SL: "סיירה לאונה",
  SG: "סינגפור",
  SX: "סינט מארטן (החלק ההולנדי)",
  SK: "סלובקיה",
  SI: "סלובניה",
  SB: "איי שלמה",
  SO: "סומליה",
  ZA: "דרום אפריקה",
  GS: "ג'ורג'ייה הדרומית ואיי סנדוויץ' הדרומיים",
  SS: "סודאן הדרומית",
  ES: "ספרד",
  LK: "סרי לנקה",
  SD: "סודאן",
  SR: "סורינאם",
  SJ: "סוואלברד ויאן מיין",
  SE: "שוודיה",
  CH: "שווייץ",
  SY: "סוריה",
  TW: "טייוואן",
  TJ: "טג'יקיסטן",
  TZ: "טנזניה",
  TH: "תאילנד",
  TL: "טימור המזרחית",
  TG: "טוגו",
  TK: "טוקלאו",
  TO: "טונגה",
  TT: "טרינידד וטובאגו",
  TN: "תוניסיה",
  TR: "טורקייה",
  TM: "טורקמניסטן",
  TC: "איי טורקס וקייקוס",
  TV: "טובאלו",
  UG: "אוגנדה",
  UA: "אוקראינה",
  AE: "איחוד האמירויות",
  GB: "הממלכה המאוחדת",
  UM: "איים זעירים של ארצות הברית",
  US: "ארצות הברית",
  UY: "אורוגוואי",
  UZ: "אוזבקיסטן",
  VU: "ונואטו",
  VE: "ונצואלה",
  VN: "וייטנאם",
  VG: "איי הבתולה (בריטניה)",
  VI: "איי הבתולה (ארה״ב)",
  WF: "ואליס ופוטונה",
  EH: "סהרה המערבית",
  YE: "תימן",
  ZM: "זמבייה",
  ZW: "זימבבואה",
  AN: "האנטילים ההולנדיים",
  GZ: "רצועת עזה",
  XK: "קוסובו",
};
